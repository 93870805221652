const React = require('react');
const { PropTypes } = require('prop-types');
const { default: SaveArticleProvider } = require('@forbes/fbs-components/dist/cjs/components/SaveArticleHandler/contexts/SaveArticleProvider');
const { useUserInfo } = require('@forbes/fbs-components/dist/cjs/components/GlobalHeader/hooks/useUserInfo');
const ForYouSignUpCard = require('./ForYouSignUpCard');
const ForYouCarousel = require('./ForYouCarousel');
const ForYouShuffle = require('./ForYouShuffle');

const { default: useFetchArticleCommentsCounts } = require('../../../../next/hooks/useFetchArticleCommentsCount');
const { fetchForYouDataWrapper } = require('../../homepage/for-you');
const { formatForYouTrackingLabel } = require('../../shared/trackingUtils');
const ForYouCard = require('./ForYouCard');
const { primeInviewEvent } = require('../../shared/tracking');

const { isProd } = window.forbes?.['simple-site'] || {};
const {
	useRef, useState, useMemo, useEffect,
} = React;

const signUpCardProps = {
	title: 'New For You',
	description: 'Sign in or Create an account for tailored recommendations based on your interests and preferences.',
	buttonText: 'Sign In Or Register',
};

const trackingProps = {
	prevLabel: 'for-you-homepage-left-arrow-click',
	nextLabel: 'for-you-homepage-right-arrow-click',
	shuffleLabel: 'for-you-homepage-shuffle-click',
	signUpLabel: 'for-you-homepage-register-click',
};

/**
 * Prepares data for the "For You" block.
 *
 * @param {Object[]} forYouData The data used to prepare the "For You" block.
 * @returns {Object} An object containing properties for the "For You" block.
 */
const prepareForYouData = (forYouData) => {
	const articleIds = (forYouData || []).map((data) => data.naturalId || data.naturalid);
	const prefix = 'for-you-homepage-article';

	const articles = (forYouData || []).map(({
		naturalId,
		naturalid,
		displayChannel,
		channelname,
		content_paywall: contentPaywall,
		templateType,
		image,
		uri,
		title,
	} = {}, index) => ({
		articleId: naturalId || naturalid || '',
		channel: displayChannel || channelname,
		isPremium: templateType === 'premium' || contentPaywall === 'premium',
		image,
		link: uri,
		title,
		trackingProps: {
			inViewLabel: formatForYouTrackingLabel({
				prefix, uri, index, event: 'in-view',
			}),
			commentingLabel: formatForYouTrackingLabel({
				prefix, uri, index, event: 'commenting',
			}),
			clickLabel: formatForYouTrackingLabel({
				prefix, uri, index, event: 'click',
			}),
			saveLabel: formatForYouTrackingLabel({
				prefix, uri, index, event: 'save',
			}),
			unSaveLabel: formatForYouTrackingLabel({
				prefix, uri, index, event: 'unsave',
			}),
		},
	}));

	return {
		articleIds,
		articles,
	};
};

const ForYou = () => {
	const { isReady, isLoggedIn } = useUserInfo();

	const forYouRef = useRef(null);
	const [forYouData, setForYouData] = useState({});
	const [articleCommentsCount, setArticleCommentsCount] = useState({});
	useFetchArticleCommentsCounts(articleCommentsCount, setArticleCommentsCount, forYouData.articleIds, isProd);

	const articleWithComments = useMemo(() => forYouData.articles?.map((article) => {
		const id = article.articleId;
		const commentId = id.startsWith('blogAndPostId') ? `content_${id.split('-')[1]}` : id;
		article.commentsCount = articleCommentsCount[commentId];

		return article;
	}), [articleCommentsCount, forYouData.articles]);

	useEffect(() => {
		if (!primeInviewEvent || !forYouRef.current || !isReady) return;

		primeInviewEvent(forYouRef.current, 'for-you-homepage-in-view', 0.05, 'scroll');
	}, [isReady]);

	useEffect(() => {
		if (!isReady) return;

		const fetchForYouArticles = async () => {
			const results = await fetchForYouDataWrapper(isLoggedIn);
			const forYouFormattedData = prepareForYouData(results.slice(0, 6));
			setForYouData(forYouFormattedData);
		};

		fetchForYouArticles();
	}, [isLoggedIn, isReady]);

	if (!isReady) {
		return null;
	}

	return (
		<SaveArticleProvider>
			<div className="for-you" ref={forYouRef}>
				{!!articleWithComments && <ForYouSignUpCard isLoggedIn={isLoggedIn} {...signUpCardProps} trackingProps={trackingProps} /> }

				{ articleWithComments && (isLoggedIn
					? <ForYouCarousel articles={articleWithComments} trackingProps={trackingProps} />
					: (
						<ForYouShuffle
							articlePerView={2}
							articles={articleWithComments}
							trackingProps={trackingProps}
						>
							<ForYouCard className="for-you-shuffle__article" size="small" />
						</ForYouShuffle>
					))}
			</div>
		</SaveArticleProvider>
	);
};

ForYou.propTypes = {
	articleIds: PropTypes.array,
	articles: PropTypes.array,
	isLoggedIn: PropTypes.bool,
	signUpCardProps: PropTypes.object,
	trackingProps: PropTypes.object,
};

module.exports = ForYou;
