/**
 * Get the edit tools body data
 * @param {Object} serverData the edittools data for body
 * @param {Object} promotedContent the promotedContent data for body
 * @returns an object of the section data
 */
const getChanSecReqBodyAndPath = (serverData, promotedContent) => {
	const {
		sectionId, channelId, name, channelName, layout, isVetted, channelColor,
	} = serverData;

	return {
		reqBody: {
			channelSectionData: {
				id: sectionId ? channelId.concat(sectionId) : channelId,
				sectionId,
				channelName,
				sectionName: name,
				layout,
				isVetted,
			},
			color: channelColor,
			promotedContent,
		},
		path: `/simple-data/chansec/${name}/rows/next`,
	};
};

export default getChanSecReqBodyAndPath;
