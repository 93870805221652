/* eslint-disable jsx-a11y/control-has-associated-label */
const React = require('react');
const cx = require('classnames');
const { PropTypes } = require('prop-types');

const { cached } = require('@forbes/fbs-icons/dist/index');

const { reactComponentsTracking } = require('../../shared/react-tracking');

const {
	useRef, useState, useMemo,
} = React;

const ForYouShuffle = ({
	articles,
	trackingProps,
	articlePerView,
	children,
	className,
}) => {
	const [activeIndex, setActiveIndex] = useState(1);
	const articlesRef = useRef(null);
	const lastTimeRef = useRef(0);

	const groupedArticles = useMemo(
		() => articles?.reduce((groupedArticle, item, index) => {
			const groupIndex = Math.floor(index / articlePerView);

			if (!groupedArticle[groupIndex]) {
				groupedArticle[groupIndex] = [];
			}

			groupedArticle[groupIndex].push(item);

			return groupedArticle;
		}, []),
		[articles, articlePerView],
	);

	const handleShuffle = () => {
		if (groupedArticles.length && Date.now() - lastTimeRef.current > 600) {
			const updatedIndex = groupedArticles.length === activeIndex ? 1 : activeIndex + 1;
			setActiveIndex(updatedIndex);
			reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.shuffleLabel);
			groupedArticles[updatedIndex - 1].forEach((article) => {
				reactComponentsTracking('Template Area Interaction', 'scroll', article.trackingProps?.inViewLabel);
			});

			lastTimeRef.current = Date.now();
		}
	};

	return (
		<div className={cx('for-you-shuffle', className)}>
			<div className="for-you-shuffle__articles" ref={articlesRef}>
				{groupedArticles?.map((groupedArticle, groupedArticleIndex) => (
					<div
						key={`${groupedArticleIndex.toString()}`}
						className={cx('for-you-shuffle__grouped', {
							'for-you-shuffle__grouped--animate': activeIndex === groupedArticleIndex + 1,
						})}
					>
						{groupedArticle?.map((article) => (
							React.Children.map(children, (child) => (
								React.cloneElement(child, { ...article })
							))
						))}
					</div>
				))}
			</div>
			<div className="for-you-shuffle__footer">
				<span className="for-you-shuffle__count" data-testid="count">
					{`${activeIndex} of ${groupedArticles?.length}`}
				</span>
				<button
					type="button"
					className="for-you-shuffle__button"
					onClick={handleShuffle}
					dangerouslySetInnerHTML={{ __html: `${cached} Smart Shuffle` }}
				/>
			</div>
		</div>
	);
};

ForYouShuffle.propTypes = {
	articles: PropTypes.array,
	articlePerView: PropTypes.number.isRequired,
	children: PropTypes.node,
	trackingProps: PropTypes.shape({
		shuffleLabel: PropTypes.string,
	}),
	className: PropTypes.string,
};

module.exports = ForYouShuffle;
