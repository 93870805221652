const React = require('react');

const { useRef } = React;
const SaveArticleProvider = require('@forbes/fbs-components/dist/cjs/components/SaveArticleHandler/contexts/SaveArticleProvider').default;
const Article = require('./Article');
const useMaxArticles = require('./useMaxArticles');
const useFetchArticles = require('./useFetchArticles');
const useCurrentArticles = require('./useCurrentArticles');

/**
 * Note: this component manages display of popular articles with complex animations.
 *
 * The component fetches articles from an API at regular intervals and formats them for display.
 * Articles are dynamically merged and revealed with animations.
 */
const PopularArticles = () => {
	const containerRef = useRef();
	const maxArticles = useMaxArticles(containerRef);
	const apiArticles = useFetchArticles();
	const currentArticles = useCurrentArticles(apiArticles, maxArticles);

	return (
		<SaveArticleProvider>
			<div className="pop-picks-top-container" ref={containerRef}>
				<div className="pop-picks-numbers-container">
					{currentArticles.map(({ id }, index) => (
						<div className="pop-picks-number-container" key={`number-container-${id}`}>
							<div className="pop-picks-number">
								{`${index + 1}.`}
							</div>
						</div>
					))}
				</div>
				<div className="pop-picks-articles-container">
					{currentArticles.map(({
						id, index, articleData, leaving, entering, changeTrending, finishedChangingTrending,
					}) => (
						<Article
							key={`article-container-${id}`}
							dataKey={id}
							index={index}
							hideBorder={index === maxArticles - 1}
							article={articleData}
							leaving={leaving}
							entering={entering}
							changeTrending={changeTrending}
							finishedChangingTrending={finishedChangingTrending}
						/>
					))}
				</div>
			</div>
		</SaveArticleProvider>
	);
};

module.exports = PopularArticles;
