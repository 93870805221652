import './shared/edittools';

import './shared/serviceWorker';
import './shared/adInsertion';
import './shared/universal-header';
import './shared/native-ads';
import './shared/stream-share';
import './shared/sliders-featured';
import './shared/pushlyPrompt';
import './shared/newsletter-vettedOffer';
import { setupCordialService } from './shared/cordialData';

import './react-components/ForYou/renderForYou';
import './react-components/PopularArticles/renderPopularArticles';

import './homepage/happening-now';
import './homepage/pop-picks';
import './homepage/brandvoice-setup';
import './homepage/edition-dropdown';
import './homepage/lazy-load';
import './homepage/marketo';
import './homepage/404-disclaimer';
import './homepage/quote-of-the-day';
import './homepage/top-ad-handler';

setupCordialService();
