const React = require('react');
const { default: CarouselPagination } = require('@forbes/fbs-components/dist/cjs/components/Carousel/CarouselPagination');
const { PropTypes } = require('prop-types');
const ForYouCard = require('./ForYouCard');

const { reactComponentsTracking } = require('../../shared/react-tracking');

const carouselConfig = {
	loop: true,
	speed: 500,
	spaceBetween: 16,
	paginationProps: {
		clickable: true,
		horizontalClass: 'for-you-carousel__bullet',
	},
	controlProps: {
		className: 'for-you-carousel__arrow',
		classNamePrev: 'for-you-carousel__prev',
		size: 'small',
	},
};

const ForYouCarousel = ({
	articles,
	trackingProps,
}) => {
	const handleSlideChangeTransitionStart = (swiper) => {
		const { realIndex } = swiper;
		const activeArticle = articles[realIndex];
		reactComponentsTracking('Template Area Interaction', 'scroll', activeArticle?.trackingProps?.inViewLabel);
	};

	return (
		<div className="for-you-carousel">
			<CarouselPagination
				className="for-you-carousel__wrapper"
				onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
				onNavigationNext={() => reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.nextLabel)}
				onNavigationPrev={() => reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.prevLabel)}
				{...carouselConfig}
			>
				{articles?.map((article) => (
					<ForYouCard
						key={`carousel-${article.articleId}`}
						size="medium"
						{...article}
					/>
				))}
			</CarouselPagination>
		</div>
	);
};

ForYouCarousel.propTypes = {
	articles: PropTypes.array,
	trackingProps: PropTypes.shape({
		prevLabel: PropTypes.string,
		nextLabel: PropTypes.string,
	}),
};

module.exports = ForYouCarousel;
