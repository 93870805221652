const { useEffect, useState, useRef } = require('react');
const {
	formatArticles,
	mergeArticles,
	leavingArticles,
	showHiddenArticles,
	clearTrendingState,
	fadeOutTrendingTexts,
} = require('./utils');
const usePrevious = require('./usePrevious');

const useCurrentArticles = (apiArticles, maxArticles) => {
	const [currentArticles, setCurrentArticles] = useState([]);
	const isFirstLoad = useRef(true);
	const maxArticlesPrevious = usePrevious(maxArticles);

	useEffect(() => {
		isFirstLoad.current = !currentArticles.length;
	}, [currentArticles.length]);

	useEffect(() => {
		if (!apiArticles.length) return;

		const formattedArticles = formatArticles(apiArticles, maxArticles);
		const maxArticlesChanged = maxArticles !== maxArticlesPrevious;
		const updateCurrentArticles = (updateFunction) => {
			setCurrentArticles((curArticles) => updateFunction(curArticles, formattedArticles, maxArticles));
		};

		if (isFirstLoad.current || maxArticlesChanged) {
			updateCurrentArticles(mergeArticles);
		} else {
			const mergeAndRevealArticles = () => {
				updateCurrentArticles(mergeArticles);
				updateCurrentArticles((curArticles) => showHiddenArticles(curArticles, true));
			};
			const revealAllArticles = () => {
				updateCurrentArticles((curArticles) => showHiddenArticles(curArticles, false));
			};

			// perform animations in correct order, timing is important!
			const animations = [
				{ fn: () => updateCurrentArticles(fadeOutTrendingTexts), delay: 0 },
				{ fn: () => updateCurrentArticles(leavingArticles), delay: 0 },
				{ fn: mergeAndRevealArticles, delay: 500 },
				{ fn: () => updateCurrentArticles(clearTrendingState), delay: 1000 },
				{ fn: revealAllArticles, delay: 1500 },
			];

			animations.forEach(({ fn, delay }) => setTimeout(fn, delay));
		}
		// ignoring warning for a ref that is coming from custom hook (usePrevious)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxArticles, apiArticles]);

	return currentArticles;
};

module.exports = useCurrentArticles;
