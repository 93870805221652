const topAdTotalPadding = 40;
let isObserverAttached = false;

/**
 * Checks if new height is more than the previously set max height and if it is
 * locks the top ad container to new max height
 * @param {Element} element DOM element to attach resize observer
 * @param {Number} height height of the newly refreshed ad
 * */
function checkAndApplyFixedHeight(element, height) {
	if (height === 0) {
		return;
	}
	const newHeight = Math.max(height + topAdTotalPadding, element.parentElement.offsetHeight);
	element.parentElement.style.minHeight = `${newHeight}px`;
}

/**
 * Attaches resize observer to the top ad element.
 * When height is changed, calls the checkAndApplyFixedHeight with a new height of the element
 * @param {Element} element DOM element to attach resize observer
 * */
function attachResizeObserver(element) {
	if (isObserverAttached) {
		return;
	}
	const resizeObj = new ResizeObserver((entries) => {
		const rect = entries[0].contentRect;
		const height = rect.height;
		checkAndApplyFixedHeight(element, height);
	});
	resizeObj.observe(element);
	isObserverAttached = true;
}

document.addEventListener('fbs-ad-load', (event) => {
	const { detail } = event;
	const slot = detail.slot.getSlotElementId();
	const element = document.getElementById(slot);

	if (slot === 'top') {
		checkAndApplyFixedHeight(element, element.offsetHeight);
		attachResizeObserver(element);
	}
});
