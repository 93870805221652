const React = require('react');

const { PropTypes } = require('prop-types');

const { default: ButtonCTA } = require('@forbes/fbs-components/dist/cjs/components/Button/ButtonCTA');
const { reactComponentsTracking } = require('../../shared/react-tracking');

const ForYouSignUpCard = ({
	title,
	description,
	buttonText,
	isLoggedIn,
	trackingProps,
}) => {
	const buttonClickHandler = () => {
		window.Zephr?.utils?.showLogin(trackingProps?.registerSource || 'homepage-for-you');
		reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.signUpLabel);
	};

	return (
		<div className="for-you-sign-up">
			<p className="for-you-sign-up__title">{title}</p>
			{!isLoggedIn && (
				<>
					<p className="for-you-sign-up__description">{description}</p>
					<ButtonCTA
						className="for-you-sign-up__button"
						size="medium"
						variant="secondary"
						onClick={buttonClickHandler}
					>
						{buttonText}
					</ButtonCTA>
				</>
			)}
		</div>

	);
};

ForYouSignUpCard.propTypes = {
	title: PropTypes.string,
	isLoggedIn: PropTypes.bool,
	description: PropTypes.string,
	buttonText: PropTypes.string,
	trackingProps: PropTypes.shape({
		signUpLabel: PropTypes.string,
		registerSource: PropTypes.string,
	}),
};

module.exports = ForYouSignUpCard;
