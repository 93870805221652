import checkStatus from '../shared/checkStatus';
import { fireGAEvent, primeInviewEvent } from '../shared/tracking';
import { truncateCarouselTitles } from '../shared/carouselUtilities';

// eslint-disable-next-line import/no-unresolved, import/extensions
import { template } from '../../../../dist/templates/_editorsPicks';

document.addEventListener('DOMContentLoaded', () => {
	const isEuropeanHomepage = window.forbes['simple-site'].isEuropeanHomepage;
	const popPicks = document.querySelector('.channel__sidebar--pop-picks');
	const popularBtn = popPicks.querySelector('.pop-picks__nav-btn--popular');
	const picksBtn = popPicks.querySelector('.pop-picks__nav-btn--picks');
	const popularContent = popPicks.querySelector('.pop-picks__content--popular');
	const picksContent = popPicks.querySelector('.pop-picks__content--picks');
	const borderElement = popPicks.querySelector('.pop-picks__nav-border');
	let popularOnScreen = true;
	let picksData = null;
	let picksItems;

	function setUpBorder() {
		const activeBtn = popularOnScreen ? popularBtn : picksBtn;
		borderElement.style.width = `${activeBtn.clientWidth}px`;
		borderElement.style.transform = `translateX(${activeBtn.offsetLeft}px)`;
	}

	function hideLargeScreenPicks() {
		const contentHeight = picksContent.clientHeight;
		// we are standing on popular tab so contentHeight for picks tab will be 0
		if (contentHeight === 0) {
			return;
		}
		const previousLastVisible = document.querySelector('.editors-pick--last-visible');
		if (previousLastVisible) {
			previousLastVisible.classList.remove('editors-pick--last-visible');
		}

		let lastVisibileItem;

		(picksItems || []).forEach((pick) => {
			if (pick.offsetTop + pick.clientHeight > contentHeight - 20) {
				pick.classList.add('editors-pick--hidden');
				pick.setAttribute('aria-hidden', 'true');
				pick.setAttribute('tabindex', '-1');
				lastVisibileItem?.classList.add('editors-pick--last-visible');
			} else {
				pick.classList.remove('editors-pick--hidden');
				pick.removeAttribute('aria-hidden', 'true');
				pick.removeAttribute('tabindex', '-1');
				lastVisibileItem = pick;
			}
		});
	}

	function checkToHidePicks() {
		if (window.innerWidth > 768) {
			hideLargeScreenPicks();
		}
	}

	function setupTrackingForPicks() {
		picksItems.forEach((element, index) => {
			const eventLabel = `editors-picks-article-${index + 1}`;
			primeInviewEvent(element, eventLabel, 1, 'in-view', '', 'home');
			const articleLink = element.querySelector('.data-viz__title');
			articleLink.addEventListener('click', (e) => {
				e.preventDefault();
				fireGAEvent({
					eventCategory: 'home',
					eventAction: 'click',
					eventLabel,
				});
				setTimeout(() => {
					window.location.href = articleLink.getAttribute('href');
				}, 100);
			});
		});
	}

	function insertPicks() {
		const data = {
			picksData,
			isEuropeanHomepage,
		};
		picksContent.innerHTML = template(data);
		picksItems = [...picksContent.querySelectorAll('.editors-pick')];
		setTimeout(() => {
			truncateCarouselTitles(picksContent, '.data-viz__title');
			checkToHidePicks();
			setupTrackingForPicks();
		});
	}

	function getPicksData() {
		const endpoint = isEuropeanHomepage ? '/simple-data/latest-picks-eu/' : '/simple-data/editors-picks/';
		fetch(endpoint)
			.then((response) => checkStatus(response))
			.then((res) => res.json())
			.then((res) => {
				picksData = res;
				insertPicks();
			})
			.catch((error) => console.error(error));
	}

	function onClickPopular() {
		fireGAEvent({ eventCategory: 'home', eventAction: 'click', eventLabel: 'trending' });
		if (!popularOnScreen) {
			popularOnScreen = true;
			popularBtn?.classList.add('pop-picks__nav-btn--active');
			picksBtn?.classList.remove('pop-picks__nav-btn--active');
			popularContent?.classList.add('pop-picks__content--active');
			picksContent?.classList.remove('pop-picks__content--active');
			setUpBorder();
		}
	}

	function onClickPicks() {
		fireGAEvent({ eventCategory: 'home', eventAction: 'click', eventLabel: 'editors-picks' });
		if (popularOnScreen || isEuropeanHomepage) {
			popularOnScreen = false;
			popularBtn?.classList.remove('pop-picks__nav-btn--active');
			picksBtn?.classList.add('pop-picks__nav-btn--active');
			popularContent?.classList.remove('pop-picks__content--active');
			picksContent?.classList.add('pop-picks__content--active');
			setUpBorder();

			if (picksData) {
				checkToHidePicks();
			} else {
				getPicksData();
			}
		}
	}

	function setUpEventHandlers() {
		if (popularBtn) {
			popularBtn.onclick = onClickPopular;
		}
		if (picksBtn) {
			picksBtn.onclick = onClickPicks;
		}
		if (isEuropeanHomepage && picksBtn) {
			// for european homepage we want to load this immediately
			// since this tab is the only tab that will be visible
			onClickPicks();
		}

		window.addEventListener('resize', () => {
			setUpBorder();
			checkToHidePicks();
		});
	}

	setUpEventHandlers();
	setTimeout(setUpBorder, 500);
	primeInviewEvent(document.querySelector('.pop-picks__content--picks'), 'Homepage Trending In-View', 0.05);
});
