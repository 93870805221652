import React from 'react';
import ReactDOM from 'react-dom';

import ForYou from './ForYou';

/**
 * Renders the "For You" Component.
 */
const renderForYou = () => {
	const mainElement = document.querySelector('.for-you__component');
	ReactDOM.render(<ForYou />, mainElement);
};

renderForYou();
