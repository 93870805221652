/* eslint-disable jsx-a11y/control-has-associated-label */
const React = require('react');
const { PropTypes } = require('prop-types');

const cx = require('classnames');
const { default: GardenTruncation } = require('@forbes/fbs-components/dist/cjs/components/Truncation/Truncation');
const { default: SaveArticle } = require('@forbes/fbs-components/dist/cjs/components/SaveArticle/SaveArticle');

const { comment } = require('@forbes/fbs-icons/dist/index');
const { reactComponentsTracking } = require('../../shared/react-tracking');

const ForYouCard = ({
	size,
	title,
	image,
	channel,
	isPremium,
	link,
	target,
	articleId,
	className,
	commentsCount = 0,
	trackingProps,
}) => (
	<div className={cx('for-you-card', className, size)}>
		<div className="for-you-card__badge">
			{isPremium && <span className="for-you-card__premium">Premium</span>}
			<span className="for-you-card__channel">{channel}</span>
		</div>
		<div className="for-you-card__wrapper">
			<a
				data-testid="image"
				href={link}
				onClick={() => reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.clickLabel)}
				target={target}
				className="for-you-card__img-wrapper"
			>
				<img className="for-you-card__img" src={image} alt={title} />
			</a>
			<div className="for-you-card__title-wrapper">
				<a
					data-testid="title"
					href={link}
					onClick={() => reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.clickLabel)}
					target={target}
					className="for-you-card__title"
				>
					<GardenTruncation lines={2}>{title}</GardenTruncation>
				</a>
				<div className="for-you-card__icons">
					{commentsCount >= 5 && (
						<a
							data-testid="commenting"
							href={`${link}#open-web-0`}
							className={cx('for-you-card__comment-btn', 'for-you-card__icon')}
							onClick={() => reactComponentsTracking('Template Area Interaction', 'click', trackingProps?.commentingLabel)}
							dangerouslySetInnerHTML={{ __html: `${comment} ${commentsCount}` }}
						/>
					)}
					<SaveArticle
						className="for-you-card__save-btn"
						articleId={articleId}
						trackingProps={{
							track: reactComponentsTracking,
							category: 'Template Area Interaction',
							action: 'click',
							unSaveLabel: trackingProps?.unSaveLabel,
							saveLabel: trackingProps?.saveLabel,
						}}
					/>
				</div>
			</div>
		</div>
	</div>
);

ForYouCard.propTypes = {
	size: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
	image: PropTypes.string,
	channel: PropTypes.string,
	isPremium: PropTypes.bool,
	link: PropTypes.string,
	target: PropTypes.string,
	articleId: PropTypes.string,
	commentsCount: PropTypes.number,
	trackingProps: PropTypes.shape({
		clickLabel: PropTypes.string,
		commentingLabel: PropTypes.string,
		saveLabel: PropTypes.string,
		unSaveLabel: PropTypes.string,
	}),
};

module.exports = ForYouCard;
