import { useEffect } from 'react';

const useFetchArticleCommentsCount = (articleCommentsCount, setArticleCommentsCount, articleIds, isProd) => {
	useEffect(() => {
		const spotId = isProd ? 'sp_qBnNq7ll' : 'sp_EgBmvxVv';

		const fetchArticlesCommentsCount = async () => {
			const ids = articleIds?.filter((id) => typeof articleCommentsCount[id] === 'undefined');
			const postsIds = ids?.map((id) => (id.startsWith('blogAndPostId') ? `content_${id.split('-')[1]}` : id));

			if (ids?.length) {
				try {
					const fetchCommentsCount = await fetch(`https://open-api.spot.im/v1/messages-count?spot_id=${spotId}&posts_ids=${postsIds.join(',')}`);
					const openWebMessagesCount = await fetchCommentsCount.json();

					const commentsCount = { ...openWebMessagesCount.messages_count };

					// Open web doesn't include the ids in the response if they have zero comments
					// This loop is necessary to add the ids that have zero comments so that the fetch doesn't happen again
					ids.forEach((id) => {
						if (!commentsCount[id]) {
							commentsCount[id] = 0;
						}
					});

					setArticleCommentsCount((prevState) => ({
						...prevState,
						...commentsCount,
					}));
				} catch (error) {
					console.error(error);
				}
			}
		};

		fetchArticlesCommentsCount();
	}, [articleCommentsCount, articleIds, setArticleCommentsCount, isProd]);

	return articleCommentsCount;
};

export default useFetchArticleCommentsCount;
