const { useState, useEffect } = require('react');

const { ARTICLE_HEIGHT, ARTICLE_GAP } = require('./utils');
const { isMobile } = require('../../shared/is-mobile');

const useMaxArticles = (containerRef) => {
	const [maxArticles, setMaxArticles] = useState(isMobile ? 5 : 0);

	const debounce = (func, timeout = 150) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	};

	useEffect(() => {
		const updateMaxArticles = () => {
			if (containerRef.current) {
				const containerHeight = document.querySelector('.pop-picks__content--active').clientHeight;
				if (containerHeight === 0) {
					return;
				}
				const numArticles = Math.floor((containerHeight + ARTICLE_GAP) / ARTICLE_HEIGHT);
				setMaxArticles(numArticles);
			}
		};
		if (isMobile) {
			return () => {};
		}
		const debouncedUpdateMaxArticles = debounce(updateMaxArticles);
		updateMaxArticles();
		window.addEventListener('resize', debouncedUpdateMaxArticles);
		return () => window.removeEventListener('resize', debouncedUpdateMaxArticles);
	}, [containerRef]);

	return maxArticles;
};

module.exports = useMaxArticles;
