const { useRef, useEffect } = require('react');

const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

module.exports = usePrevious;
