import React from 'react';
import ReactDOM from 'react-dom';

import PopularArticles from './PopularArticles';

/**
 * Renders the "Popular Articles" Component.
 */
const renderForYou = () => {
	const mainElement = document.querySelector('.pop-picks__content--popular');
	if (mainElement) {
		ReactDOM.render(<PopularArticles />, mainElement);
	}
};

renderForYou();
