const { useState, useEffect, useRef } = require('react');
const { useIdleTimer } = require('react-idle-timer');
const { fetchArticles, PING_INTERVAL } = require('./utils');

const IDLE_TIMEOUT = 60000;

const useFetchArticles = () => {
	const [apiArticles, setApiArticles] = useState([]);
	const [isIdle, setIsIdle] = useState(false);
	const lastFetchTime = useRef(Date.now());

	const loadArticles = async () => {
		try {
			lastFetchTime.current = Date.now();
			const articles = await fetchArticles();
			if (articles?.length) setApiArticles(articles);
		} catch (e) {
			console.error('error while fetching trending articles', e);
		}
	};

	useEffect(() => {
		loadArticles();
	}, []);

	useEffect(() => {
		let interval;
		if (!isIdle) {
			interval = setInterval(loadArticles, PING_INTERVAL);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [isIdle]);

	const handleOnIdle = () => {
		setIsIdle(true);
	};

	const handleOnActive = () => {
		setIsIdle(false);
		if (Date.now() - lastFetchTime.current > PING_INTERVAL) {
			loadArticles();
		}
	};

	useIdleTimer({
		timeout: IDLE_TIMEOUT,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
	});

	return apiArticles;
};

module.exports = useFetchArticles;
